<template>
  <div class="login">
    <div v-if="!loading">
      <!-- show login when not authenticated -->
      <div v-if="!loggedIn" @click="login">Signup/Log in</div>
      <!-- show logout when authenticated -->
      <div v-if="loggedIn" @click="logout">Log out</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Auth',
  computed:
    mapState({
      loggedIn: (state) => state.auth.loggedIn,
      loading: (state) => state.loading,
    }),
  methods: {
    login() {
      this.$store.dispatch('auth/login');
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
  beforeMount() {
    if (this.loggedIn) {
      this.logout();
    } else {
      this.login();
    }
  },
};
</script>
